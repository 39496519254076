<template>
  <v-row>
    <v-col class="d-inline-flex align-center" cols="12">
      <v-icon id="infra">{{ icon }}</v-icon>{{ name }}
      <v-select disabled class="d-inline-flex align-center ml-4" :items=dummy item-text="people" item-value="cost" label="住宅によって決定されます"></v-select>
    </v-col>
    <v-col>
      <v-card min-height="100%" min-width="80%">
        <v-card-title>{{ title }}</v-card-title>
        <v-layout justify-center>
          <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/electricity/electricity.jpg')"></v-img>
        </v-layout>
        <v-card-title>{{ this.$store.state.breakdown.electricity.toLocaleString() }}円</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-lightning-bolt',
    name: '電気',
    title: '電気代',
    dummy: []
  })
})
</script>
