<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="house">{{ icon }}</v-icon>{{ name }}
        <v-select class="d-inline-flex align-center mt-2 ml-4" :items="this.$store.state.login_response.house" item-text="select" return-object :label="this.$store.state.select.house" @change="value_house"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="4" lg="4" v-for="house in this.$store.state.login_response.house" :key="house.key">
            <v-card>
              <v-card-title>{{ house.type+house.id }}</v-card-title>
              <v-col align="center">
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/house/'+house.img+'.jpg')"></v-img>
              </v-col>
              <v-card-text>
                <v-simple-table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>間取り</td>
                      <td>{{ house.layout }}</td>
                    </tr>
                    <tr>
                      <td>タイプ</td>
                      <td>{{ house.type }}</td>
                    </tr>
                    <tr>
                      <td>立地</td>
                      <td>{{ house.location }}</td>
                    </tr>
                    <tr>
                      <td>階数</td>
                      <td>{{ house.floors }}階</td>
                    </tr>
                    <tr>
                      <td>築年数</td>
                      <td>{{ house.age }}年</td>
                    </tr>
                    <tr>
                      <td>広さ</td>
                      <td>{{ house.square }}平米</td>
                    </tr>
                    <tr>
                      <td>駅徒歩</td>
                      <td>{{ house.walk }}分</td>
                    </tr>
                    <tr>
                      <td>販売価格</td>
                      <td>{{ house.purchase.toLocaleString() }}万円</td>
                    </tr>
                    <tr>
                      <td>月々支払い</td>
                      <td>{{ house.rent.toLocaleString() }}円</td>
                    </tr>
                    <tr>
                      <td>備考</td>
                      <td>{{ house.note }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-home',
    name: '住宅'
  }),
  methods: {
    value_house: function(value) {
      console.log('value', value);
      this.$store.commit('value_house', value);
      this.$store.commit('value_electricity', value.rent);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  }
})
</script>
