<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="saving">{{ icon }}</v-icon>{{ name }}
      </v-col>
      <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
        <v-card>
          <v-card-title>貯金額</v-card-title>
          <v-layout justify-center>
            <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/saving/saving.jpg')"></v-img>
          </v-layout>
          <v-col>
            <v-select :items="selects" v-model="saving" :label="this.$store.state.select.saving" @change="value_saving"></v-select>
            <!-- <v-text-field type="number" label="貯金額を入力してください" suffix=",000円" v-model.number="saving" @change="value_saving"></v-text-field> -->
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-cash-fast',
    name: '貯金',
    saving: 0,
    selects: []
  }),
  methods: {
    value_saving: function(value) {
      this.$store.commit('value_saving', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  },
  computed: {
    set_value: function(){
      return this.$store.state.breakdown.saving;
    }
  },
  created() {
    for(var i = 0; i <= 200; i++){
      this.selects[i] = i * 1000;
    }
    this.saving = this.$store.state.breakdown.saving;
  }
})
</script>
