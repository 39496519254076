<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="life_insurance">{{ icon }}</v-icon>{{ name }}
        <v-img max-height="80" max-width="150" contain v-bind:src="require('@/assets/gibraltar.jpg')" v-if="this.$store.state.login_response.queryStringParameters.target == 'gibraltar' || this.$store.state.login_response.queryStringParameters.target == 'shinagawa'"></v-img>
      </v-col>
      <v-col>
        <v-sheet
          color="grey lighten-2"
          elevation="10"
        >
          <v-col>
            <v-switch v-model="isShowYou" prepend-icon="mdi-eye" inset hide-details></v-switch>
            <p v-show="isShowYou">
            あなた<v-select class="d-inline-flex align-center mt-2 ml-4" :items=this.$store.state.login_response.life_insurance_you item-text="select" return-object :label="$store.state.select.life_insurance.you" @change="value_life_insurance_you"></v-select>
            </p>
          </v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="4" sm="4" md="3" lg="3" v-for="you in this.$store.state.login_response.life_insurance_you" :key="you.key">
                <v-card>
                  <v-card-text>
                    <v-simple-table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>性別</td>
                          <td v-if="you.gender == 1">男性</td>
                          <td v-else>女性</td>
                        </tr>
                        <tr>
                          <td>年齢</td>
                          <td>{{ you.age }}歳</td>
                        </tr>
                        <tr>
                          <td>保険金</td>
                          <td>{{ you.insurance.toLocaleString() }}万円</td>
                        </tr>
                        <tr>
                          <td>保険料</td>
                          <td>{{ you.premium.toLocaleString() }}円</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <div v-if="this.$store.state.login_response.persona.is_married">
            <v-col>
              <v-switch v-model="isShowSpouse" prepend-icon="mdi-eye" inset hide-details></v-switch>
              <p v-show="isShowSpouse">
              配偶者<v-select class="d-inline-flex align-center mt-2 ml-4" :items=this.$store.state.login_response.life_insurance_spouse item-text="select" return-object :label="$store.state.select.life_insurance.spouse" @change="value_life_insurance_spouse"></v-select>
              </p>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="mt-2" cols="4" sm="4" md="3" lg="3"  v-for="spouse in this.$store.state.login_response.life_insurance_spouse" :key="spouse.key">
                  <v-card>
                    <v-card-text>
                      <v-simple-table>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <td>性別</td>
                            <td v-if="spouse.gender == 1">男性</td>
                            <td v-else>女性</td>
                          </tr>
                          <tr>
                            <td>年齢</td>
                            <td>{{ spouse.age }}歳</td>
                          </tr>
                          <tr>
                            <td>保険金</td>
                            <td>{{ spouse.insurance.toLocaleString() }}万円</td>
                          </tr>
                          <tr>
                            <td>保険料</td>
                            <td>{{ spouse.premium.toLocaleString() }}円</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
          <v-col class="mt-2" cols="4" sm="4" md="2" lg="2">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (
                  this.$store.state.breakdown.life_insurance_you +
                  this.$store.state.breakdown.life_insurance_spouse
                ).toLocaleString() }}円
              </v-card-title>
            </v-card>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data() {
    return {
      icon: 'mdi-lifebuoy',
      name: '生命保険',
      isShowYou: true,
      isShowSpouse: true
    }
  },
  methods: {
    value_life_insurance_you: function(value) {
      this.$store.commit('value_life_insurance_you', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_life_insurance_spouse: function(value) {
      this.$store.commit('value_life_insurance_spouse', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  },
})
</script>
