import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  data: {
    i: 0
  },
  state: {
    key: 0,
    isFetching: false,
    login_response: {},
    error: null,
    people: {
      gas: 0,
      water: 0
    },
    breakdown: {
      balance: 0,
      house: 0,
      electricity: 0,
      gas: 0,
      water: 0,
      main_food1: 0,
      main_food2: 0,
      main_food3: 0,
      main_food4: 0,
      side_food1: 0,
      side_food2: 0,
      side_food3: 0,
      side_food4: 0,
      fashion_men: new Array(),
      fashion_women: new Array(),
      fashion_kids: new Array(),
      fashion_baby: new Array(),
      car: 0,
      smartphone: 0,
      media_newspaper: 0,
      media_tv_radio: 0,
      media_books: 0,
      media_web: 0,
      media_isp: 0,
      daily_necessities_dental: 0,
      daily_necessities_laundry: 0,
      daily_necessities_sanitary: 0,
      daily_necessities_bath: 0,
      daily_necessities_kitchen: 0,
      daily_necessities_etc: 0,
      medical_medicine_internal: 0,
      medical_medicine_analgesic1: 0,
      medical_medicine_analgesic2: 0,
      medical_medicine_external: 0,
      medical_medicine_etc: 0,
      medical_medicine_supplement: 0,
      medical_medicine_children: 0,
      medical_physical: new Array(),
      saving: 0,
      life_insurance_you: 0,
      life_insurance_spouse: 0,
      eat_out: new Array(),
      lesson_adult: new Array(),
      lesson_child: new Array(),
      emergency_kit: 0,
      trip: new Array()
    },
    progresses: {
      progress_house: false,
      progress_electricity: false,
      progress_gas: false,
      progress_water: false,
      progress_food: false,
      progress_fashion: false,
      progress_car: false,
      progress_smartphone: false,
      progress_media: false,
      progress_daily_necessities: false,
      progress_medical: false,
      progress_saving: false,
      progress_life_insurance: false,
      progress_eat_out: false,
      progress_lesson: false,
      progress_emergency_kit: false,
      progress_trip: false
    },
    select: {
      house: "選択してください",
      gas: "家族の人数を選択してください",
      water: "家族の人数を選択してください",
      main_food: [
        "選択してください",
        "選択してください",
        "選択してください",
        "選択してください"
      ],
      side_food: [
        "選択してください",
        "選択してください",
        "選択してください",
        "選択してください"
      ],
      fashion_men: new Array(100).fill('0'),
      fashion_women: new Array(100).fill('0'),
      fashion_kids: new Array(100).fill('0'),
      fashion_baby: new Array(100).fill('0'),
      car: "選択してください",
      smartphone: "選択してください",
      media: {
        newspaper: [],
        tv_radio: [],
        books: [],
        web: [],
        isp: []
      },
      daily_necessities: {
        dental: [],
        laundry: [],
        sanitary: [],
        bath: [],
        kitchen: [],
        etc: []
      },
      medical_medicine: {
        internal: [],
        analgesic1: [],
        analgesic2: [],
        external: [],
        etc: [],
        supplement: [],
        children: []
      },
      medical_physical: new Array(100).fill('選択してください'),
      saving: "貯金額を選択してください",
      life_insurance: {
        you: "選択してください",
        spouse: "選択してください"
      },
      eat_out: new Array(100).fill('0'),
      lesson: {
        adult: new Array(100).fill('0'),
        child: new Array(100).fill('0')
      },
      emergency_kit:"選択してください",
      trip: {
        adult: new Array(100).fill('大人0'),
        child: new Array(100).fill('子供0')
      }
    },
    weeks: 4,
    progress: 0,
    contents: 17,
    target: null,
    env: null,
    is_debug: false
  },
  mutations: {
    set_login_response: function(state, value) {
      state.login_response = value;
      console.log('state.login_response', state.login_response);
    },
    set_balance: function(state, value) {
      state.breakdown.balance = value;
      console.log('state.breakdown.balance', state.breakdown.balance);
    },
    rebalance: function(state) {
      state.breakdown.balance = 
      (state.login_response.persona.net_monthly_income +state.login_response.persona.lucky_card)- 
      state.breakdown.house - 
      state.breakdown.electricity - 
      state.breakdown.gas - 
      state.breakdown.main_food1 - 
      state.breakdown.main_food2 - 
      state.breakdown.main_food3 - 
      state.breakdown.main_food4 - 
      state.breakdown.side_food1 - 
      state.breakdown.side_food2 - 
      state.breakdown.side_food3 - 
      state.breakdown.side_food4 - 
      state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.car - 
      state.breakdown.smartphone - 
      state.breakdown.media_newspaper - 
      state.breakdown.media_tv_radio - 
      state.breakdown.media_books - 
      state.breakdown.media_web - 
      state.breakdown.media_isp - 
      state.breakdown.daily_necessities_dental - 
      state.breakdown.daily_necessities_laundry - 
      state.breakdown.daily_necessities_sanitary - 
      state.breakdown.daily_necessities_bath - 
      state.breakdown.daily_necessities_kitchen - 
      state.breakdown.daily_necessities_etc - 
      state.breakdown.medical_medicine_internal - 
      state.breakdown.medical_medicine_analgesic1 - 
      state.breakdown.medical_medicine_analgesic2 - 
      state.breakdown.medical_medicine_external - 
      state.breakdown.medical_medicine_etc - 
      state.breakdown.medical_medicine_supplement - 
      state.breakdown.medical_medicine_children - 
      state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.saving - 
      state.breakdown.life_insurance_you - 
      state.breakdown.life_insurance_spouse - 
      state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0) - 
      state.breakdown.emergency_kit - 
      state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult; }, 0) - 
      state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child; }, 0);
      state.breakdown.balance = parseInt(state.breakdown.balance);
      console.log('state.breakdown.balance', state.breakdown.balance);
    },
    value_house: function(state, value) {
      state.breakdown.house = value.rent;
      console.log('state.breakdown.house', state.breakdown.house);
      state.select.house = value.select;
      console.log('state.select.house', state.select.house);
    },
    value_electricity: function(state) {
      if(state.breakdown.house <= 40000){
        state.breakdown.electricity = 3500;
      }else if(state.breakdown.house >= 40001 && state.breakdown.house <= 50000){
        state.breakdown.electricity = 5500;
      }else if(state.breakdown.house >= 50001 && state.breakdown.house <= 60000){
        state.breakdown.electricity = 6500;
      }else if(state.breakdown.house >= 60001 && state.breakdown.house <= 70000){
        state.breakdown.electricity = 7500;
      }else if(state.breakdown.house >= 70001 && state.breakdown.house <= 80000){
        state.breakdown.electricity = 8500;
      }else if(state.breakdown.house >= 80001 && state.breakdown.house <= 90000){
        state.breakdown.electricity = 9500;
      }else if(state.breakdown.house >= 90001 && state.breakdown.house <= 100000){
        state.breakdown.electricity = 10500;
      }else if(state.breakdown.house >= 100001 && state.breakdown.house <= 110000){
        state.breakdown.electricity = 11500;
      }
      console.log('state.breakdown.electricity', state.breakdown.electricity);
    },
    value_gas: function(state, value) {
      state.people.gas = value.people;
      console.log('state.people.gas', state.people.gas);
      state.breakdown.gas = value.cost;
      console.log('state.breakdown.gas', state.breakdown.gas);
      state.select.gas = value.select;
      console.log('state.select.gas', state.select.gas);
    },
    value_water: function(state, value) {
      state.people.water = value.people;
      console.log('state.people.water', state.people.water);
      state.breakdown.water = value.cost;
      console.log('state.breakdown.water', state.breakdown.water);
      state.select.water = value.select;
      console.log('state.select.water', state.select.water);
    },
    value_main_food1: function(state, value) {
      state.breakdown.main_food1 = value.cost;
      console.log('state.breakdown.main_food1', state.breakdown.main_food1);
      state.select.main_food[0] = value.course;
      console.log('state.select.main_food[0]', state.select.main_food[0]);
    },
    value_main_food2: function(state, value) {
      state.breakdown.main_food2 = value.cost;
      console.log('state.breakdown.main_food2', state.breakdown.main_food2);
      state.select.main_food[1] = value.course;
      console.log('state.select.main_food[1]', state.select.main_food[1]);
    },
    value_main_food3: function(state, value) {
      state.breakdown.main_food3 = value.cost;
      console.log('state.breakdown.main_food3', state.breakdown.main_food3);
      state.select.main_food[2] = value.course;
      console.log('state.select.main_food[2]', state.select.main_food[2]);
    },
    value_main_food4: function(state, value) {
      state.breakdown.main_food4 = value.cost;
      console.log('state.breakdown.main_food4', state.breakdown.main_food4);
      state.select.main_food[3] = value.course;
      console.log('state.select.main_food[3]', state.select.main_food[3]);
    },
    value_side_food1: function(state, value) {
      state.breakdown.side_food1 = value.cost;
      console.log('state.breakdown.side_food1', state.breakdown.side_food1);
      state.select.side_food[0] = value.course;
      console.log('state.select.side_food[0]', state.select.side_food[0]);
    },
    value_side_food2: function(state, value) {
      state.breakdown.side_food2 = value.cost;
      console.log('state.breakdown.side_food2', state.breakdown.side_food2);
      state.select.side_food[1] = value.course;
      console.log('state.select.side_food[1]', state.select.side_food[1]);
    },
    value_side_food3: function(state, value) {
      state.breakdown.side_food3 = value.cost;
      console.log('state.breakdown.side_food3', state.breakdown.side_food3);
      state.select.side_food[2] = value.course;
      console.log('state.select.side_food[2]', state.select.side_food[2]);
    },
    value_side_food4: function(state, value) {
      state.breakdown.side_food4 = value.cost;
      console.log('state.breakdown.side_food4', state.breakdown.side_food4);
      state.select.side_food[3] = value.course;
      console.log('state.select.side_food[3]', state.select.side_food[3]);
    },
    initialize_fashion_men: function(state, value) {
      state.breakdown.fashion_men = value;
    },
    value_fashion_men: function(state, {index, num}) {
      state.breakdown.fashion_men[index].num = num;
      state.breakdown.fashion_men[index].order = state.breakdown.fashion_men[index].cost * state.breakdown.fashion_men[index].num;
      console.log('state.breakdown.fashion_men.reduce', state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.fashion_men[index] = String(num);
      console.log('state.select.fashion_men[index]', state.select.fashion_men[index]);
    },
    initialize_fashion_women: function(state, value) {
      state.breakdown.fashion_women = value;
    },
    value_fashion_women: function(state, {index, num}) {
      state.breakdown.fashion_women[index].num = num;
      state.breakdown.fashion_women[index].order = state.breakdown.fashion_women[index].cost * state.breakdown.fashion_women[index].num;
      console.log('state.breakdown.fashion_women.reduce', state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.fashion_women[index] = String(num);
      console.log('state.select.fashion_women[index]', state.select.fashion_women[index]);
    },
    initialize_fashion_kids: function(state, value) {
      state.breakdown.fashion_kids = value;
    },
    value_fashion_kids: function(state, {index, num}) {
      state.breakdown.fashion_kids[index].num = num;
      state.breakdown.fashion_kids[index].order = state.breakdown.fashion_kids[index].cost * state.breakdown.fashion_kids[index].num;
      console.log('state.breakdown.fashion_kids.reduce', state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.fashion_kids[index] = String(num);
      console.log('state.select.fashion_kids[index]', state.select.fashion_kids[index]);
    },
    initialize_fashion_baby: function(state, value) {
      state.breakdown.fashion_baby = value;
    },
    value_fashion_baby: function(state, {index, num}) {
      state.breakdown.fashion_baby[index].num = num;
      state.breakdown.fashion_baby[index].order = state.breakdown.fashion_baby[index].cost * state.breakdown.fashion_baby[index].num;
      console.log('state.breakdown.fashion_baby.reduce', state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.fashion_baby[index] = String(num);
      console.log('state.select.fashion_baby[index]', state.select.fashion_baby[index]);
    },
    value_car: function(state, value) {
      state.breakdown.car = value.cost;
      console.log('state.breakdown.car', state.breakdown.car);
      state.select.car = value.model;
      console.log('state.select.car', state.select.car);
    },
    value_smartphone: function(state, value) {
      state.breakdown.smartphone = value.cost;
      console.log('state.breakdown.smartphone', state.breakdown.smartphone);
      state.select.smartphone = value.plan;
      console.log('state.select.smartphone', state.select.smartphone);
    },
    value_media_newspaper: function(state, value) {
      state.select.media.newspaper = value;
      console.log('state.select.media.newspaper', state.select.media.newspaper);
      state.breakdown.media_newspaper = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.media_newspaper += value[this.i].cost;
      }
      console.log('state.breakdown.media_newspaper', state.breakdown.media_newspaper);
    },
    value_media_tv_radio: function(state, value) {
      state.select.media.tv_radio = value;
      console.log('state.select.media.tv_radio', state.select.media.tv_radio);
      state.breakdown.media_tv_radio = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.media_tv_radio += value[this.i].cost;
      }
      console.log('state.breakdown.media_tv_radio', state.breakdown.media_tv_radio);
    },
    value_media_books: function(state, value) {
      state.select.media.books = value;
      console.log('state.select.media.books', state.select.media.books);
      state.breakdown.media_books = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.media_books += value[this.i].cost;
      }
      console.log('state.breakdown.media_books', state.breakdown.media_books);
    },
    value_media_web: function(state, value) {
      state.select.media.web = value;
      console.log('state.select.media.web', state.select.media.web);
      state.breakdown.media_web = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.media_web += value[this.i].cost;
      }
      console.log('state.breakdown.media_web', state.breakdown.media_web);
    },
    value_media_isp: function(state, value) {
      state.select.media.isp = value;
      console.log('state.select.media.isp', state.select.media.isp);
      state.breakdown.media_isp = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.media_isp += value[this.i].cost;
      }
      console.log('state.breakdown.media_isp', state.breakdown.media_isp);
    },
    value_daily_necessities_dental: function(state, value) {
      state.select.daily_necessities.dental = value;
      console.log('state.select.daily_necessities.dental', state.select.daily_necessities.dental);
      state.breakdown.daily_necessities_dental = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.daily_necessities_dental += value[this.i].cost;
      }
      console.log('state.breakdown.daily_necessities_dental', state.breakdown.daily_necessities_dental);
    },
    value_daily_necessities_laundry: function(state, value) {
      state.select.daily_necessities.laundry = value;
      console.log('state.select.daily_necessities.laundry', state.select.daily_necessities.laundry);
      state.breakdown.daily_necessities_laundry = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.daily_necessities_laundry += value[this.i].cost;
      }
      console.log('state.breakdown.daily_necessities_laundry', state.breakdown.daily_necessities_laundry);
    },
    value_daily_necessities_sanitary: function(state, value) {
      state.select.daily_necessities.sanitary = value;
      console.log('state.select.daily_necessities.sanitary', state.select.daily_necessities.sanitary);
      state.breakdown.daily_necessities_sanitary = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.daily_necessities_sanitary += value[this.i].cost;
      }
      console.log('state.breakdown.daily_necessities_sanitary', state.breakdown.daily_necessities_sanitary);
    },
    value_daily_necessities_bath: function(state, value) {
      state.select.daily_necessities.bath = value;
      console.log('state.select.daily_necessities.bath', state.select.daily_necessities.bath);
      state.breakdown.daily_necessities_bath = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.daily_necessities_bath += value[this.i].cost;
      }
      console.log('state.breakdown.daily_necessities_bath', state.breakdown.daily_necessities_bath);
    },
    value_daily_necessities_kitchen: function(state, value) {
      state.select.daily_necessities.kitchen = value;
      console.log('state.select.daily_necessities.kitchen', state.select.daily_necessities.kitchen);
      state.breakdown.daily_necessities_kitchen = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.daily_necessities_kitchen += value[this.i].cost;
      }
      console.log('state.breakdown.daily_necessities_kitchen', state.breakdown.daily_necessities_kitchen);
    },
    value_daily_necessities_etc: function(state, value) {
      state.select.daily_necessities.etc = value;
      console.log('state.select.daily_necessities.etc', state.select.daily_necessities.etc);
      state.breakdown.daily_necessities_etc = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.daily_necessities_etc += value[this.i].cost;
      }
      console.log('state.breakdown.daily_necessities_etc', state.breakdown.daily_necessities_etc);
    },
    value_medical_medicine_internal: function(state, value) {
      state.select.medical_medicine.internal = value;
      console.log('state.select.medical_medicine.internal', state.select.medical_medicine.internal);
      state.breakdown.medical_medicine_internal = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_internal += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_internal', state.breakdown.medical_medicine_internal);
    },
    value_medical_medicine_analgesic1: function(state, value) {
      state.select.medical_medicine.analgesic1 = value;
      console.log('state.select.medical_medicine.analgesic1', state.select.medical_medicine.analgesic1);
      state.breakdown.medical_medicine_analgesic1 = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_analgesic1 += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_analgesic1', state.breakdown.medical_medicine_analgesic1);
    },
    value_medical_medicine_analgesic2: function(state, value) {
      state.select.medical_medicine.analgesic2 = value;
      console.log('state.select.medical_medicine.analgesic2', state.select.medical_medicine.analgesic2);
      state.breakdown.medical_medicine_analgesic2 = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_analgesic2 += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_analgesic2', state.breakdown.medical_medicine_analgesic2);
    },
    value_medical_medicine_external: function(state, value) {
      state.select.medical_medicine.external = value;
      console.log('state.select.medical_medicine.external', state.select.medical_medicine.external);
      state.breakdown.medical_medicine_external = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_external += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_external', state.breakdown.medical_medicine_external);
    },
    value_medical_medicine_etc: function(state, value) {
      state.select.medical_medicine.etc = value;
      console.log('state.select.medical_medicine.etc', state.select.medical_medicine.etc);
      state.breakdown.medical_medicine_etc = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_etc += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_etc', state.breakdown.medical_medicine_etc);
    },
    value_medical_medicine_supplement: function(state, value) {
      state.select.medical_medicine.supplement = value;
      console.log('state.select.medical_medicine.supplement', state.select.medical_medicine.supplement);
      state.breakdown.medical_medicine_supplement = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_supplement += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_supplement', state.breakdown.medical_medicine_supplement);
    },
    value_medical_medicine_children: function(state, value) {
      state.select.medical_medicine.children = value;
      console.log('state.select.medical_medicine.children', state.select.medical_medicine.children);
      state.breakdown.medical_medicine_children = 0;
      for(this.i = 0; this.i < Object.keys(value).length; this.i++){
        state.breakdown.medical_medicine_children += value[this.i].cost;
      }
      console.log('state.breakdown.medical_medicine_children', state.breakdown.medical_medicine_children);
    },
    initialize_medical_physical: function(state, value) {
      state.breakdown.medical_physical = value;
    },
    value_medical_physical: function(state, {index, num}) {
      state.breakdown.medical_physical[index].num = num;
      state.breakdown.medical_physical[index].order = state.breakdown.medical_physical[index].cost * state.breakdown.medical_physical[index].num;
      console.log('state.breakdown.medical_physical.reduce', state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.medical_physical[index] = String(num);
      console.log('state.select.medical_physical[index]', state.select.medical_physical[index]);
    },
    value_saving: function(state, value) {
      state.breakdown.saving = value;
      console.log('state.breakdown.saving', state.breakdown.saving);
      state.select.saving = String(value);
      console.log('state.select.saving', state.select.saving);
    },
    value_life_insurance_you: function(state, value) {
      state.breakdown.life_insurance_you = value.premium;
      console.log('state.breakdown.life_insurance_you', state.breakdown.life_insurance_you);
      state.select.life_insurance.you = value.select;
      console.log('state.select.life_insurance.you', state.select.life_insurance.you);
    },
    value_life_insurance_spouse: function(state, value) {
      state.breakdown.life_insurance_spouse = value.premium;
      console.log('state.breakdown.life_insurance_spouse', state.breakdown.life_insurance_spouse);
      state.select.life_insurance.spouse = value.select;
      console.log('state.select.life_insurance.spouse', state.select.life_insurance.spouse);
    },
    initialize_eat_out: function(state, value) {
      state.breakdown.eat_out = value;
    },
    value_eat_out: function(state, {index, num}) {
      state.breakdown.eat_out[index].num = num;
      state.breakdown.eat_out[index].order = state.breakdown.eat_out[index].cost * state.breakdown.eat_out[index].num;
      console.log('state.breakdown.eat_out.reduce', state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.eat_out[index] = String(num);
      console.log('state.select.eat_out[index]', state.select.eat_out[index]);
    },
    initialize_lesson_adult: function(state, value) {
      state.breakdown.lesson_adult = value;
    },
    value_lesson_adult: function(state, {index, num}) {
      state.breakdown.lesson_adult[index].num = num;
      state.breakdown.lesson_adult[index].order = state.breakdown.lesson_adult[index].cost * state.breakdown.lesson_adult[index].num;
      console.log('state.breakdown.lesson_adult.reduce', state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.lesson.adult[index] = String(num);
      console.log('state.select.lesson.adult[index]', state.select.lesson.adult[index]);
    },
    initialize_lesson_child: function(state, value) {
      state.breakdown.lesson_child = value;
    },
    value_lesson_child: function(state, {index, num}) {
      state.breakdown.lesson_child[index].num = num;
      state.breakdown.lesson_child[index].order = state.breakdown.lesson_child[index].cost * state.breakdown.lesson_child[index].num;
      console.log('state.breakdown.lesson_child.reduce', state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0));
      state.select.lesson.child[index] = String(num);
      console.log('state.select.lesson.child[index]', state.select.lesson.child[index]);
    },
    value_emergency_kit: function(state, value) {
      state.breakdown.emergency_kit = value.cost;
      console.log('state.breakdown.emergency_kit', state.breakdown.emergency_kit);
      state.select.emergency_kit = value.name;
      console.log('state.select.emergency_kit', state.select.emergency_kit);
    },
    initialize_trip: function(state, value) {
      state.breakdown.trip = value;
    },
    value_trip_adult: function(state, {index, num}) {
      state.breakdown.trip[index].num_adult = num;
      state.breakdown.trip[index].order_adult = state.breakdown.trip[index].cost_adult * state.breakdown.trip[index].num_adult;
      console.log('state.breakdown.trip.reduce', state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult + i.order_child; }, 0));
      state.select.trip.adult[index] = '大人' + String(num) + '人';
      console.log('state.select.trip.adult[index]', state.select.trip.adult[index]);
    },
    value_trip_child: function(state, {index, num}) {
      state.breakdown.trip[index].num_child = num;
      state.breakdown.trip[index].order_child = state.breakdown.trip[index].cost_child * state.breakdown.trip[index].num_child;
      console.log('state.breakdown.trip.reduce', state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child + i.order_adult; }, 0));
      state.select.trip.child[index] = '子供' + String(num) + '人';
      console.log('state.select.trip.child[index]', state.select.trip.child[index]);
    },
    set_progress: function(state) {
      state.progresses.progress_house = (state.breakdown.house != 0) ? true : false;
      state.progresses.progress_electricity = (state.breakdown.electricity != 0) ? true : false;
      state.progresses.progress_gas = (state.breakdown.gas != 0) ? true : false;
      state.progresses.progress_water = (state.breakdown.water != 0) ? true : false;
      state.progresses.progress_food = ((state.breakdown.main_food1 + state.breakdown.main_food1 + state.breakdown.main_food2 + state.breakdown.main_food3 + state.breakdown.main_food4 + state.breakdown.side_food1 + state.breakdown.side_food2 + state.breakdown.side_food3 + state.breakdown.side_food4) != 0) ? true : false;
      state.progresses.progress_fashion = ((state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0) + state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0) + state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0) + state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0)) != 0) ? true : false;
      state.progresses.progress_car = (state.breakdown.car != 0) ? true : false;
      state.progresses.progress_smartphone = (state.breakdown.smartphone != 0) ? true : false;
      state.progresses.progress_media = ((state.breakdown.media_newspaper + state.breakdown.media_tv_radio + state.breakdown.media_books + state.breakdown.media_web + state.breakdown.media_isp) != 0) ? true : false;
      state.progresses.progress_daily_necessities = ((state.breakdown.daily_necessities_dental + state.breakdown.daily_necessities_laundry + state.breakdown.daily_necessities_sanitary + state.breakdown.daily_necessities_bath + state.breakdown.daily_necessities_kitchen + state.breakdown.daily_necessities_etc) != 0) ? true : false;
      state.progresses.progress_medical = ((state.breakdown.medical_medicine_internal + state.breakdown.medical_medicine_analgesic1 + state.breakdown.medical_medicine_analgesic2 + state.breakdown.medical_medicine_external + state.breakdown.medical_medicine_etc + state.breakdown.medical_medicine_supplement + state.breakdown.medical_medicine_children + state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0)) != 0) ? true : false;
      state.progresses.progress_saving = (state.breakdown.saving != 0) ? true : false;
      state.progresses.progress_life_insurance = ((state.breakdown.life_insurance_you + state.breakdown.life_insurance_spouse) != 0) ? true : false;
      state.progresses.progress_eat_out = ((state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0)) != 0) ? true : false;
      state.progresses.progress_lesson = ((state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0) + state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0)) != 0) ? true : false;
      state.progresses.progress_emergency_kit = (state.breakdown.emergency_kit != 0) ? true : false;
      state.progresses.progress_trip = ((state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult; }, 0) + state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child; }, 0)) != 0) ? true : false;
      state.progress = state.progresses.progress_house + 
        state.progresses.progress_electricity + 
        state.progresses.progress_gas + 
        state.progresses.progress_water + 
        state.progresses.progress_food + 
        state.progresses.progress_fashion + 
        state.progresses.progress_car + 
        state.progresses.progress_smartphone + 
        state.progresses.progress_media + 
        state.progresses.progress_daily_necessities + 
        state.progresses.progress_medical + 
        state.progresses.progress_saving + 
        state.progresses.progress_life_insurance + 
        state.progresses.progress_eat_out + 
        state.progresses.progress_lesson + 
        state.progresses.progress_emergency_kit + 
        state.progresses.progress_trip;
      console.log('state.progress', state.progress);
    },
    set_target: function(state, value) {
      state.target = value;
      console.log('state.target', state.target);
    },
    set_env: function(state, value) {
      state.env = value;
      console.log('state.env', state.env);
    },
    set_stock_intermediate_text: function(state, index) {
      state.login_response.stock[index].intermediate_text = state.login_response.stock[index].intermediate_price ;
    },
    set_stock_closing_text: function(state, index) {
      state.login_response.stock[index].closing_text = state.login_response.stock[index].closing_price ;
    },
    set_is_debug: function(state, value) {
      state.is_debug = value;
      console.log('state.is_debug', state.is_debug);
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    { // ストレージのキーを指定。デフォルトではvuex
      key: 'financeParkApp',

      // 管理対象のステートを指定。pathsを書かない時は`modules`に書いたモジュールに含まれるステート全て。`[]`の時はどれも保存されない
      // paths: [],

      // ストレージの種類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage
    }
  )]
})
