<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="emergency_kit">{{ icon }}</v-icon>{{ name }}
        <v-select class="d-inline-flex align-center mt-2 ml-4" :items=this.$store.state.login_response.emergency_kit item-text="name" item-value="cost" return-object :label="this.$store.state.select.emergency_kit" @change="value_emergency_kit"></v-select>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="kit in this.$store.state.login_response.emergency_kit" :key="kit.key">
            <v-card>
              <v-card-title>{{ kit.name }}</v-card-title>
              <v-col align="center">
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/emergency_kit/'+kit.img+'.jpg')"></v-img>
              </v-col>
              <v-card-text>
                <v-simple-table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>状態</td>
                      <td>{{ kit.content }}</td>
                    </tr>
                    <tr>
                      <td>支払い金額</td>
                      <td>{{ kit.cost.toLocaleString() }}円</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-bag-personal',
    name: '防災用品'
  }),
  methods: {
    value_emergency_kit: function(value) {
      this.$store.commit('value_emergency_kit', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  }
})
</script>
