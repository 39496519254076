<template>
  <v-app>
    <div id='capture'>
      <v-container class="overflow-y-auto">
        <v-row>
          <v-col>
            <v-card class="ma-2" cols="12" sm="12" md="12" lg="12">
              <v-layout justify-center>
                <v-img max-height="150" max-width="150" contain v-bind:src="require('@/assets/ja.jpg')"></v-img>
                <v-img max-height="150" max-width="150" contain v-bind:src="require('@/assets/shinagawa.jpg')" v-if="this.$store.state.target == 'shinagawa'"></v-img>
                <v-img max-height="150" max-width="150" contain v-bind:src="require('@/assets/gibraltar_sq.jpg')" v-if="this.$store.state.target == 'gibraltar'"></v-img>
              </v-layout>
              <v-card-title>
                {{ this.$store.state.login_response.queryStringParameters.name }}さん
                <br>
                計画が完了しました。お疲れ様でした。こちらがあなたの計画バランスです。
              </v-card-title>
              <v-simple-table dense>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>NMI</td>
                    <td>{{ this.$store.state.login_response.persona.net_monthly_income.toLocaleString() }}円</td>
                  </tr>
                  <tr>
                    <td>ラッキーカード</td>
                    <td>{{ this.$store.state.login_response.persona.lucky_card.toLocaleString() }}円</td>
                  </tr>
                  <tr>
                    <td>計画合計金額</td>
                    <td>{{ (this.$store.state.login_response.persona.net_monthly_income - this.$store.state.breakdown.balance).toLocaleString() }}円</td>
                  </tr>
                  <tr>
                    <td>残金</td>
                    <td>{{ this.$store.state.breakdown.balance.toLocaleString() }}円</td>
                  </tr>
                  <tr>
                    <td>株資産額</td>
                    <td>{{ (this.$store.state.login_response.stock[0].closing_price * this.$store.state.login_response.stock[0].share + this.$store.state.login_response.stock[1].closing_price * this.$store.state.login_response.stock[1].share + this.$store.state.login_response.stock[2].closing_price * this.$store.state.login_response.stock[2].share).toLocaleString() }}円</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-col>
                <v-sheet color="grey lighten-2" elevation="10">
                  <v-simple-table dense fixed-header>
                    <thead>
                      <th>銘柄</th>
                      <th>資産額</th>
                    </thead>
                    <tbody>
                      <tr v-for="item in stock" :key="item.brand">
                        <td>{{ item.brand }}</td>
                        <td>{{ (item.share * item.closing_price).toLocaleString() }}円</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-sheet>
              </v-col>
              <v-card-text class="chart-container"> 
                  <PieChart />
              </v-card-text>
              <v-card-text class="chart-container"> 
                  <HorizontalBarChart />
              </v-card-text>
            </v-card>
            <br>
            <v-card class="ma-2" cols="12" sm="12" md="12" lg="12">
              <v-card-title>
                外国為替
              </v-card-title>
              <v-card-text>
                今日体験したファイナンス・パークでは、旅行プランを選ぶオプションがありました。<br>
                外国旅行を選んだ人も国内旅行を選んだ人もいました。<br>
                <br>
                ここでは、海外旅行をする際に使うお金をどのように用立てるかを体験します。<br>
                <br>
                まずは、旅行に行く国を選んでください。
                <v-select :items="country_list" v-model="country" label="国名を選択してください。"></v-select>
                <br>
                お小遣い10万円を持って、その国へ旅行します。<br>
                日本円は外国では使えないので、外国のお金に両替します。<br>
                <br>
                あなたが選んだ国の通貨はなんと言うでしょう？
                <v-select :items="currency_list" v-model="currency" label="通貨を選択してください。"></v-select>
                <br>
                日本円をその国の通貨へ両替します。<br>
                日本円100,000円はいくらになるでしょう？<br>
                <br>
                1{{currency}} = <v-text-field type="number" v-model="exchange_rate1" @change="calc_exchange1" suffix="円"></v-text-field>
                <v-card-title>100,000円 = {{foreign_currency.toLocaleString()}}{{currency}}</v-card-title>
                色々な場所を観光して、たくさん美味しいものを食べて<br>
                海外旅行を楽しみ、お土産も買いました。<br>
                お小遣いはちょうど半分になっていました。<br>
                お小遣いの残りの額は、{{remaining_foreign_currency.toLocaleString()}}{{currency}}。
                <br>
                持ち帰ってきた外貨を日本で円に両替しました。<br>
                いくら残りましたか？<br>
                <br>
                1{{currency}} = <v-text-field type="number" v-model="exchange_rate2" @change="calc_exchange2" suffix="円"></v-text-field>
                <v-card-title>{{remaining_yen.toLocaleString()}}円が残りました。</v-card-title>
                円を外貨へ両替するときと、外貨を円へ両替するときではレートが異なるということを是非覚えておきましょう。
              </v-card-text>
            </v-card>
            <v-col class="mt-2" cols="6" sm="6" md="4" lg="4">
                <v-btn class="mr-4" :disabled="screenshot" @click="captureImage" rounded x-large >スクリーンショット</v-btn>
            </v-col>
          </v-col>
        </v-row>
        <br><br>
      </v-container>
    </div>
    <v-footer app height=40>
      <v-row align-content="center">
        <v-col align="center">
          ©Junior Achievement Japan All rights reserved.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import PieChart from '../components/piechart.vue';
import HorizontalBarChart from '../components/horizontalbarchart.vue';
import html2canvas from 'html2canvas'

export default ({
  data: () => ({
    stock: [],
    country_list: ['アメリカ', '韓国', '中国', 'インドネシア', 'ドイツ'],
    country: null,
    currency_list: ['ドル', 'ウォン', '元', 'ルピア', 'ユーロ'],
    currency: null,
    exchange_rate_list1: [130, 0.1, 20, 0.01, 135],
    exchange_rate1: null,
    foreign_currency: 0,
    remaining_foreign_currency: 0,
    exchange_rate2: null,
    remaining_yen: 0,
    screenshot: false
  }),
  components: {
    PieChart,
    HorizontalBarChart
  },
  methods: {
    calc_exchange1(){
      this.foreign_currency = Math.round(100000 / this.exchange_rate1);
      this.remaining_foreign_currency = Math.round(this.foreign_currency / 2);
    },
    calc_exchange2(){
      this.remaining_yen = Math.round(this.remaining_foreign_currency * this.exchange_rate2);
    },
    captureImage () {
      this.screenshot = true;
      html2canvas(document.querySelector('#capture'), {
        scale: 1
      }).then((canvas) => {
        const link = document.createElement('a')
        link.href = canvas.toDataURL()
        link.download = `fp_screenshot_complete.png`
        link.click()
      })
      setTimeout(this.enableScreenshot, 5000);
    },
    enableScreenshot () {
      this.screenshot = false;
    }
  },
  created(){
    this.stock = this.$store.state.login_response.stock;
  },
  mounted() {
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", function() {
      window.history.pushState(null, null, null);
      return;
    });
  }
})
</script>