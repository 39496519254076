<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="medical">{{ icon }}</v-icon>{{ name }}
      </v-col>
      <v-col>
        <v-sheet
          color="grey lighten-2"
          elevation="10"
        >
          <v-col>医薬品</v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>内服薬</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_internal.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.internal" :items=this.$store.state.login_response.medical_medicine_internal item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_internal"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_internal.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>鎮痛薬(薬剤師対応の第一類)</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_analgesic1.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.analgesic1" :items=this.$store.state.login_response.medical_medicine_analgesic1 item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_analgesic1"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_analgesic1.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>鎮痛薬(第二類・第三類)</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_analgesic2.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.analgesic2" :items=this.$store.state.login_response.medical_medicine_analgesic2 item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_analgesic2"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_analgesic2.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>外用薬等</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_external.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.external" :items=this.$store.state.login_response.medical_medicine_external item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_external"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_external.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>その他</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_etc.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.etc" :items=this.$store.state.login_response.medical_medicine_etc item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_etc"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_etc.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>サプリメント</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_supplement.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.supplement" :items=this.$store.state.login_response.medical_medicine_supplement item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_supplement"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_supplement.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
                <v-card>
                  <v-card-title>小児医薬品</v-card-title>
                  <v-layout justify-center>
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/medical_medicine_children.jpg')"></v-img>
                  </v-layout>
                  <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.medical_medicine.children" :items=this.$store.state.login_response.medical_medicine_children item-text="select" item-value="cost" return-object label="選択してください" @change="value_medical_medicine_children"></v-select>
                  <v-card-subtitle>小計{{ this.$store.state.breakdown.medical_medicine_children.toLocaleString() }}円</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>小計{{ (this.$store.state.breakdown.medical_medicine_internal + this.$store.state.breakdown.medical_medicine_analgesic1 + this.$store.state.breakdown.medical_medicine_analgesic2 + this.$store.state.breakdown.medical_medicine_external + this.$store.state.breakdown.medical_medicine_etc + this.$store.state.breakdown.medical_medicine_supplement + this.$store.state.breakdown.medical_medicine_children).toLocaleString() }}円</v-col>
          <v-col>健康診断</v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="(physical, index) in this.$store.state.login_response.medical_physical" :key="index">
                <v-card>
                  <v-card-title>{{ physical.item }}<br>{{ physical.cost.toLocaleString() }}円</v-card-title>
                  <v-card-subtitle>{{ physical.note }}</v-card-subtitle>
                  <v-col align="center">
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/medical/'+physical.img+'.jpg')"></v-img>
                  </v-col>
                  <v-card-text>
                    <v-col>
                      {{ physical.content }}
                    </v-col>
                    <br>
                    <v-col>
                      <v-row>
                        <v-select :items=array_physical return-object :label="$store.state.select.medical_physical[index]" dense @change="value_medical_physical(index, $event)"></v-select>
                      </v-row>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>小計{{ this.$store.state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (
                this.$store.state.breakdown.medical_medicine_internal + 
                this.$store.state.breakdown.medical_medicine_analgesic1 + 
                this.$store.state.breakdown.medical_medicine_analgesic2 + 
                this.$store.state.breakdown.medical_medicine_external + 
                this.$store.state.breakdown.medical_medicine_etc + 
                this.$store.state.breakdown.medical_medicine_supplement + 
                this.$store.state.breakdown.medical_medicine_children +
                this.$store.state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0)
              ).toLocaleString() }}円
              </v-card-title>
            </v-card>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-pill',
    name: '医療',
    people: 1,
    array_physical: []
  }),
  methods: {
    value_medical_medicine_internal: function(value) {
      this.$store.commit('value_medical_medicine_internal', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_medicine_analgesic1: function(value) {
      this.$store.commit('value_medical_medicine_analgesic1', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_medicine_analgesic2: function(value) {
      this.$store.commit('value_medical_medicine_analgesic2', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_medicine_external: function(value) {
      this.$store.commit('value_medical_medicine_external', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_medicine_etc: function(value) {
      this.$store.commit('value_medical_medicine_etc', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_medicine_supplement: function(value) {
      this.$store.commit('value_medical_medicine_supplement', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_medicine_children: function(value) {
      this.$store.commit('value_medical_medicine_children', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_medical_physical: function(index, num) {
      this.$store.commit('value_medical_physical', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1) {
      this.people++;
    }
    if('child1_age' in this.$store.state.login_response.persona) {
      this.people++;
    }
    if('child2_age' in this.$store.state.login_response.persona) {
      this.people++;
    }
    this.array_physical = [...Array(this.people+1)].map((_, i) => i);
    this.$store.commit('initialize_medical_physical', this.$store.state.login_response.medical_physical);
  }
})
</script>
