<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="eat_out">{{ icon }}</v-icon>{{ name }}
      </v-col>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="(eat_out, index) in this.$store.state.login_response.eat_out" :key="index">
            <v-card>
              <v-card-title>{{ eat_out.course }}</v-card-title>
              <v-col align="center">
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/eat_out/'+eat_out.img+'.jpg')"></v-img>
              </v-col>
              <v-card-text>
                <v-simple-table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>メニュー</td>
                      <td>{{ eat_out.menu }}</td>
                    </tr>
                    <tr>
                      <td>1人当たり金額</td>
                      <td>{{ eat_out.cost.toLocaleString() }}円</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <br>
                <v-select :items=array return-object :label="$store.state.select.eat_out[index]" dense @change="value_eat_out(index, $event)"></v-select>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ this.$store.state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-silverware',
    name: '外食',
    cost_total: 0,
    people: 1,
    array: []
  }),
  methods: {
    generateArray() {
      this.array = [...Array(this.people+1).keys()];
    },
    value_eat_out: function(index, num) {
      this.$store.commit('value_eat_out', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1){
      this.people++;
    }
    if('child1_age' in this.$store.state.login_response.persona){
      this.people++;
    }
    if('child2_age' in this.$store.state.login_response.persona){
      this.people++;
    }
    this.generateArray();
    this.$store.commit('initialize_eat_out', this.$store.state.login_response.eat_out);
  }
})
</script>
