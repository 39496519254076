<template>
  <v-app id="inspire">
    <v-app-bar app dense hide-on-scroll clipped-left>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>
        JA Japan ファイナンス・パーク
      </v-toolbar-title>
      <!-- <v-btn class="mr-4" rounded @click="debug" v-if="this.$store.state.is_debug == true">Auto Select</v-btn> -->
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" clipped temporary> <!--左ペイン-->
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title grey--text text--darken-2">
              JA Japan<br>Finance Park
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list> <!--dense navもあり-->
          <v-list-item v-for="nav_list in nav_lists" :key="nav_list.name" v-scroll-to="{element:nav_list.link}">
            <v-list-item-icon>
              <v-icon>{{ nav_list.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <div id='capture'>
      <v-main> <!--右ペイン-->
        <v-container fluid class="grey lighten-2 overflow-y-auto">
          <v-row>
            <v-col class="mt-2" cols="6" sm="6" md="4" lg="4"><personaCard /></v-col>
            <v-col class="mt-2" cols="6" sm="6" md="8" lg="8"><barchartCard /></v-col>
          </v-row>
          <v-row><house /></v-row>
          <v-row>
            <v-col class="mt-2" cols="6" sm="6" md="4" lg="4"><electricity /></v-col>
            <v-col class="mt-2" cols="6" sm="6" md="4" lg="4"><gas /></v-col>
            <v-col class="mt-2" cols="6" sm="6" md="4" lg="4"><water /></v-col>
          </v-row>
          <v-row><food /></v-row>
          <v-row><fashion /></v-row>
          <v-row><car /></v-row>
          <v-row><smartphone /></v-row>
          <v-row><media /></v-row>
          <v-row><dailyNecessities /></v-row>
          <v-row><stock2 /></v-row>
          <v-row><medical /></v-row>
          <v-row><saving /></v-row>
          <v-row><lifeInsurance /></v-row>
          <v-row><eatOut /></v-row>
          <v-row><lesson /></v-row>
          <v-row><emergencyKit /></v-row>
          <v-row><trip /></v-row>
          <v-row><stock3 /></v-row>
          <v-row>
            <v-col class="mt-2" cols="6" sm="6" md="4" lg="4">
              <v-btn class="mr-4" :disabled="dialog" @click="submit" rounded x-large>送信</v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="dialog" persistent width="400">
            <v-card cols="3" sm="3" md="2" lg="2" width="400" max-width="400">
              <v-btn icon :disabled="communication" @click="close_dialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-text style="white-space: pre-line">
                <v-progress-linear :active="communication" :indeterminate="communication" color="orange" class="mb-0"></v-progress-linear>
                {{ this.error_message }}
              </v-card-text>
            </v-card>
          </v-dialog>
          <br>
        </v-container>
      </v-main>
    </div>
    <v-footer app height=40>
      <v-row align-content="center">
        <v-col align="center">
          <v-progress-linear color="orange" v-model="calc_progress_per" :buffer-value="100" height=16>
            {{ this.$store.state.progress }} / {{ this.$store.state.contents }}
          </v-progress-linear>
          ©Junior Achievement Japan All rights reserved.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import personaCard from '../components/persona-card.vue';
import barchartCard from '../components/barchart-card.vue';
import house from '../components/house.vue';
import electricity from '../components/electricity.vue';
import gas from '../components/gas.vue';
import water from '../components/water.vue';
import food from '../components/food.vue';
import fashion from '../components/fashion.vue';
import car from '../components/car.vue';
import smartphone from '../components/smartphone.vue';
import media from '../components/media.vue';
import dailyNecessities from '../components/daily-necessities.vue';
import stock2 from '../components/stock2.vue';
import medical from '../components/medical.vue';
import saving from '../components/saving.vue';
import lifeInsurance from '../components/life-insurance.vue';
import eatOut from '../components/eat-out.vue';
import lesson from '../components/lesson.vue';
import emergencyKit from '../components/emergency-kit.vue';
import trip from '../components/trip.vue';
import stock3 from '../components/stock3.vue';
import axios from 'axios';
// import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return{
      drawer: null,
      nav_lists:[
        {name: 'ペルソナ', icon: 'mdi-account', link: '#persona'},
        {name: '住宅', icon: 'mdi-home', link: '#house'},
        {name: '電気・ガス・水道', icon: 'mdi-cards-heart', link: '#infra'},
        {name: '食事', icon: 'mdi-rice', link: '#food'},
        {name: 'ファッション', icon: 'mdi-hanger', link: '#fashion'},
        {name: '自動車', icon: 'mdi-car', link: '#car'},
        {name: 'スマートフォン', icon: 'mdi-cellphone', link: '#smartphone'},
        {name: 'メディア', icon: 'mdi-newspaper-variant-outline', link: '#media'},
        {name: '日用品', icon: 'mdi-paper-roll', link: '#daily_necessities'},
        {name: '株式 中間値', icon: 'mdi-chart-line', link: '#stock2'},
        {name: '医療', icon: 'mdi-pill', link: '#medical'},
        {name: '貯金', icon: 'mdi-cash-fast', link: '#saving'},
        {name: '生命保険', icon: 'mdi-lifebuoy', link: '#life_insurance'},
        {name: '外食', icon: 'mdi-silverware', link: '#eat_out'},
        {name: '習い事', icon: 'mdi-school', link: '#lesson'},
        {name: '防災用品', icon: 'mdi-bag-personal', link: '#emergency_kit'},
        {name: '旅行', icon: 'mdi-airplane', link: '#trip'},
        {name: '株式 終値', icon: 'mdi-chart-line', link: '#stock3'}
      ],
      dialog: false,
      communication: false,
      screenshot: false,
      error_message: ''
    }
  },
  components: {
    personaCard,
    barchartCard,
    house,
    electricity,
    gas,
    water,
    food,
    fashion,
    car,
    smartphone,
    media,
    dailyNecessities,
    stock2,
    medical,
    saving,
    lifeInsurance,
    eatOut,
    lesson,
    emergencyKit,
    trip,
    stock3
  },
  methods: {
    submit() {
      if (this.dialog) return;
      this.dialog = true;
      this.communication = true;
      this.doSomething()
        .then(() => {
          axios.post('https://to8butpz6e.execute-api.ap-northeast-1.amazonaws.com/submit', 
            {
              target: this.$store.state.target,
              env: this.$store.state.env,
              name: this.$store.state.login_response.queryStringParameters.name,
              nmi: this.$store.state.login_response.persona.net_monthly_income, 
              lucky_card: this.$store.state.login_response.persona.lucky_card, 
              is_married: this.$store.state.login_response.persona.is_married, 
              people: this.$store.state.login_response.persona.people, 
              balance: this.$store.state.breakdown.balance, 
              house: this.$store.state.breakdown.house, 
              electricity: this.$store.state.breakdown.electricity, 
              gas: this.$store.state.breakdown.gas, 
              people_gas: this.$store.state.people.gas,
              water: this.$store.state.breakdown.water,
              people_water: this.$store.state.people.water,
              food: this.$store.state.breakdown.main_food1 + 
              this.$store.state.breakdown.main_food2 + 
              this.$store.state.breakdown.main_food3 + 
              this.$store.state.breakdown.main_food4 + 
              this.$store.state.breakdown.side_food1 + 
              this.$store.state.breakdown.side_food2 + 
              this.$store.state.breakdown.side_food3 + 
              this.$store.state.breakdown.side_food4, 
              main_food1: this.$store.state.breakdown.main_food1, 
              main_food2: this.$store.state.breakdown.main_food2, 
              main_food3: this.$store.state.breakdown.main_food3, 
              main_food4: this.$store.state.breakdown.main_food4, 
              side_food1: this.$store.state.breakdown.side_food1, 
              side_food2: this.$store.state.breakdown.side_food2, 
              side_food3: this.$store.state.breakdown.side_food3, 
              side_food4: this.$store.state.breakdown.side_food4, 
              fashion: this.$store.state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0) +
              this.$store.state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0) +
              this.$store.state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0) +
              this.$store.state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0), 
              car: this.$store.state.breakdown.car, 
              smartphone: this.$store.state.breakdown.smartphone,  
              media: this.$store.state.breakdown.media_newspaper + 
              this.$store.state.breakdown.media_tv_radio + 
              this.$store.state.breakdown.media_books + 
              this.$store.state.breakdown.media_web + 
              this.$store.state.breakdown.media_isp, 
              daily_necessities: this.$store.state.breakdown.daily_necessities_dental + 
              this.$store.state.breakdown.daily_necessities_laundry + 
              this.$store.state.breakdown.daily_necessities_sanitary + 
              this.$store.state.breakdown.daily_necessities_bath + 
              this.$store.state.breakdown.daily_necessities_kitchen + 
              this.$store.state.breakdown.daily_necessities_etc, 
              medical: this.$store.state.breakdown.medical_medicine_internal + 
              this.$store.state.breakdown.medical_medicine_analgesic1 + 
              this.$store.state.breakdown.medical_medicine_analgesic2 + 
              this.$store.state.breakdown.medical_medicine_external + 
              this.$store.state.breakdown.medical_medicine_etc + 
              this.$store.state.breakdown.medical_medicine_supplement + 
              this.$store.state.breakdown.medical_medicine_children +
              this.$store.state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0), 
              saving: this.$store.state.breakdown.saving, 
              life_insurance_you: this.$store.state.breakdown.life_insurance_you, 
              life_insurance_spouse: this.$store.state.breakdown.life_insurance_spouse, 
              eat_out: this.$store.state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0), 
              lesson: this.$store.state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0) +
              this.$store.state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0), 
              emergency_kit: this.$store.state.breakdown.emergency_kit, 
              trip: this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult; }, 0) +
              this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child; }, 0)
            }).then(response => {
            // handle success(axiosの処理が成功した場合に処理させたいことを記述)
            console.log('response', response);
            console.log('response.data', response.data);
            console.log('response.status', response.status);
            console.log('response.statusText', response.statusText);
            console.log('response.headers', response.headers);
            console.log('response.config', response.config);
            this.$router.push({ name:'Complete'});
            this.dialog = false;
          }).catch(error => {
            // handle error(axiosの処理にエラーが発生した場合に処理させたいことを記述)
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log('error.response.data', error.response.data);
              console.log('error.response.status', error.response.status);
              console.log('error.response.headers', error.response.headers);
              console.log('error.response.data.message', error.response.data.message);
              this.error_message = error.response.data.message;
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log('error.request', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error(error.message)', error.message);
            }
            console.log('error.config', error.config);
          }).finally(() => {
            // always executed(axiosの処理結果によらずいつも実行させたい処理を記述)
            this.communication = false;
          });
        })
    },
    close_dialog() {
      this.dialog = false;
      this.error_message = '';
    },
    doSomething() {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log(`Submitted on ${new Date()}`);
          resolve();
        }, 1000);
      });
    },
    debug() {
    },
    enableScreenshot () {
      this.screenshot = false;
    }
    // ...mapActions (['getPosts'])
  },
  computed: {
    // ...mapState(['login_response']),
    calc_progress_per : function() {
      return Math.floor(this.$store.state.progress * 100 / this.$store.state.contents);
    }
  },
  mounted() {
    this.$store.dispatch('getPosts')

    window.history.pushState(null, null, null);
    window.addEventListener("popstate", function() {
      window.history.pushState(null, null, null);
      return;
    });
  }
}

</script>
