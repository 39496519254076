<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="food">{{ icon }}</v-icon>{{ name }}
      </v-col>
      <v-col>
        <v-sheet color="grey lighten-2" elevation="10">
          <v-col>主食</v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="mainFood in this.$store.state.login_response.main_food" :key="mainFood.key">
                <v-card>
                  <v-card-title>コース{{ mainFood.course }}</v-card-title>
                  <v-col align="center">
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/food/'+mainFood.img+'.jpg')"></v-img>
                  </v-col>
                  <v-card-text>
                    <v-simple-table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>内容</td>
                          <td>{{ mainFood.contents1 }}</td>
                        </tr>
                        <tr>
                          <td>支出</td>
                          <td>{{ mainFood.cost.toLocaleString() }}円</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>副菜・その他</v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="sideFood in this.$store.state.login_response.side_food" :key="sideFood.key">
                <v-card>
                  <v-card-title>コース{{ sideFood.course }}</v-card-title>
                  <v-col align="center">
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/food/'+sideFood.img+'.jpg')"></v-img>
                  </v-col>
                  <v-card-text>
                    <v-simple-table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>内容1</td>
                          <td>{{ sideFood.contents1 }}</td>
                        </tr>
                        <tr>
                          <td>内容2</td>
                          <td>{{ sideFood.contents2 }}</td>
                        </tr>
                        <tr>
                          <td>支出</td>
                          <td>{{ sideFood.cost.toLocaleString() }}円</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>献立表</v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="i of weeks" :key="i.key">
                <v-card>
                  <v-card-title>{{i}}週目</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>主食</td>
                          <td>
                            <v-select class="d-inline-flex align-center mt-2 ml-4" :items=main_food_selection item-text="course" item-value="cost" return-object :label="$store.state.select.main_food[i-1]" @change="value_main_food(i, $event)"></v-select>
                          </td>
                        </tr>
                        <tr>
                          <td>副菜・その他</td>
                          <td>
                            <v-select class="d-inline-flex align-center mt-2 ml-4" :items=side_food_selection item-text="course" item-value="cost" return-object :label="$store.state.select.side_food[i-1]" @change="value_side_food(i, $event)"></v-select>
                          </td>
                        </tr>
                        <tr>
                          <td>支出</td>
                          <td>
                            {{ set_value[i-1].toLocaleString() }}円
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-2" cols="4" sm="4" md="2" lg="2">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (
                  this.$store.state.breakdown.main_food1 +
                  this.$store.state.breakdown.main_food2 +
                  this.$store.state.breakdown.main_food3 +
                  this.$store.state.breakdown.main_food4 +
                  this.$store.state.breakdown.side_food1 +
                  this.$store.state.breakdown.side_food2 +
                  this.$store.state.breakdown.side_food3 +
                  this.$store.state.breakdown.side_food4 
                ).toLocaleString() }}円
              </v-card-title>
            </v-card>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-rice',
    name: '食費',
    weeks: 4,
    main_food_selection: [],
    side_food_selection: []
  }),
  methods: {
    value_main_food: function(i, value) {
      console.log('i', i);
      console.log('value', value);
      this.$store.commit('value_main_food'+i.toString(), value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_side_food: function(i, value) {
      console.log('i', i);
      console.log('value', value);
      this.$store.commit('value_side_food'+i.toString(), value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  },
  computed: {
    set_value: function(){
      return [this.$store.state.breakdown.main_food1+this.$store.state.breakdown.side_food1, this.$store.state.breakdown.main_food2+this.$store.state.breakdown.side_food2, this.$store.state.breakdown.main_food3+this.$store.state.breakdown.side_food3, this.$store.state.breakdown.main_food4+this.$store.state.breakdown.side_food4];
    }
  },
  created: function(){
    this.main_food_selection = this.$store.state.login_response.main_food;
    this.side_food_selection = this.$store.state.login_response.side_food;
  }
})
</script>
