<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="stock3">{{ icon }}</v-icon>{{ name }}
      </v-col>
      <v-col class="my-n4" cols="12">
        <v-img max-height="40" max-width="150" contain v-bind:src="require('@/assets/daiwashoken.jpg')" v-if="this.$store.state.login_response.queryStringParameters.target == 'shinagawa'"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mt-2" cols="6" sm="6" md="4" lg="4" max-width="520">
          <v-card-title>株価の終値をお知らせします</v-card-title>
          <v-card-text>
            <v-simple-table dense fixed-header>
              <thead>
                <th>銘柄</th>
                <th>持株数</th>
                <th>終値</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.$store.state.login_response.stock" :key="item.brand">
                  <td>{{ item.brand }}</td>
                  <td>{{ item.share }}株</td>
                  <td><v-btn depressed @click="click_view_stock(index)">{{item.closing_text.toLocaleString()}}円</v-btn></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
export default({
  data: () => ({
    icon: 'mdi-chart-line',
    name: '株式 終値'
  }),
  methods: {
    value_stock3: function(value) {
      this.$store.commit('value_stock3', value);
      this.$store.commit('set_progress');
    },
    click_view_stock: function(index){
      this.$store.commit('set_stock_closing_text', index);
    }
  }
})
</script>
