<script>
import { HorizontalBar } from 'vue-chartjs'
import 'chartjs-plugin-colorschemes';
import chartjsPluginDatalabels from 'chartjs-plugin-datalabels'

export default {
    name: 'HorizontalBarChart',
    extends: HorizontalBar,
    data() {
        return {
            chartData: {
                labels: ['残金','住宅','電気','ガス','水道','食事','ファッション','自動車','スマートフォン','メディア','日用品','医療','貯金','生命保険','外食','習い事','防災用品','旅行'],
                datasets: [{
                  label: "金額",
                  data: [
                    this.$store.state.breakdown.balance,
                    this.$store.state.breakdown.house,
                    this.$store.state.breakdown.electricity,
                    this.$store.state.breakdown.gas,
                    this.$store.state.breakdown.water,
                    this.$store.state.breakdown.main_food1 + 
                    this.$store.state.breakdown.main_food2 + 
                    this.$store.state.breakdown.main_food3 + 
                    this.$store.state.breakdown.main_food4 + 
                    this.$store.state.breakdown.side_food1 + 
                    this.$store.state.breakdown.side_food2 + 
                    this.$store.state.breakdown.side_food3 + 
                    this.$store.state.breakdown.side_food4,
                    this.$store.state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0) +
                    this.$store.state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0) +
                    this.$store.state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0) +
                    this.$store.state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0),
                    this.$store.state.breakdown.car,
                    this.$store.state.breakdown.smartphone,
                    this.$store.state.breakdown.media_newspaper + 
                    this.$store.state.breakdown.media_tv_radio + 
                    this.$store.state.breakdown.media_books + 
                    this.$store.state.breakdown.media_web + 
                    this.$store.state.breakdown.media_isp,
                    this.$store.state.breakdown.daily_necessities_dental + 
                    this.$store.state.breakdown.daily_necessities_laundry + 
                    this.$store.state.breakdown.daily_necessities_sanitary + 
                    this.$store.state.breakdown.daily_necessities_bath + 
                    this.$store.state.breakdown.daily_necessities_kitchen + 
                    this.$store.state.breakdown.daily_necessities_etc,
                    this.$store.state.breakdown.medical_medicine_internal + 
                    this.$store.state.breakdown.medical_medicine_analgesic1 + 
                    this.$store.state.breakdown.medical_medicine_analgesic2 + 
                    this.$store.state.breakdown.medical_medicine_external + 
                    this.$store.state.breakdown.medical_medicine_etc + 
                    this.$store.state.breakdown.medical_medicine_supplement + 
                    this.$store.state.breakdown.medical_medicine_children +
                    this.$store.state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0),
                    this.$store.state.breakdown.saving,
                    this.$store.state.breakdown.life_insurance_you + 
                    this.$store.state.breakdown.life_insurance_spouse,
                    this.$store.state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0),
                    this.$store.state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0) +
                    this.$store.state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0),
                    this.$store.state.breakdown.emergency_kit,
                    this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult; }, 0) +
                    this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child; }, 0)
                  ],
                  fill: true,
                  datalabels: {
                      formatter: (value) => {
                          return `${value.toLocaleString()}円`
                      },
                      anchor: 'center',
                      color: 'white'
                  }
                }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true
              },
              plugins: {
                colorschemes: {
                  scheme: 'tableau.Tableau20'
                },
                datalabels: {
                  font: {
                    weight: 'bold'
                  }
                }
              }
            },
            array: new Array(),
            array_title: new Array(),
            array_value: new Array()
        }
    },
    mounted: function() {
      this.array.push({'title': '残金', 'value': this.$store.state.breakdown.balance});
      this.array.push({'title': '住宅', 'value': this.$store.state.breakdown.house});
      this.array.push({'title': '電気', 'value': this.$store.state.breakdown.electricity});
      this.array.push({'title': 'ガス', 'value': this.$store.state.breakdown.gas});
      this.array.push({'title': '水道', 'value': this.$store.state.breakdown.water});
      this.array.push({'title': '食事', 'value': this.$store.state.breakdown.main_food1 + 
                        this.$store.state.breakdown.main_food2 + 
                        this.$store.state.breakdown.main_food3 + 
                        this.$store.state.breakdown.main_food4 + 
                        this.$store.state.breakdown.side_food1 + 
                        this.$store.state.breakdown.side_food2 + 
                        this.$store.state.breakdown.side_food3 + 
                        this.$store.state.breakdown.side_food4});
      this.array.push({'title': 'ファッション', 'value': this.$store.state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0) +
                        this.$store.state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0) +
                        this.$store.state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0) +
                        this.$store.state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0)});
      this.array.push({'title': '自動車', 'value': this.$store.state.breakdown.car});
      this.array.push({'title': 'スマートフォン', 'value': this.$store.state.breakdown.smartphone});
      this.array.push({'title': 'メディア', 'value': this.$store.state.breakdown.media_newspaper + 
                        this.$store.state.breakdown.media_tv_radio + 
                        this.$store.state.breakdown.media_books + 
                        this.$store.state.breakdown.media_web + 
                        this.$store.state.breakdown.media_isp});
      this.array.push({'title': '日用品', 'value': this.$store.state.breakdown.daily_necessities_dental + 
                        this.$store.state.breakdown.daily_necessities_laundry + 
                        this.$store.state.breakdown.daily_necessities_sanitary + 
                        this.$store.state.breakdown.daily_necessities_bath + 
                        this.$store.state.breakdown.daily_necessities_kitchen + 
                        this.$store.state.breakdown.daily_necessities_etc});
      this.array.push({'title': '医療', 'value': this.$store.state.breakdown.medical_medicine_internal + 
                        this.$store.state.breakdown.medical_medicine_analgesic1 + 
                        this.$store.state.breakdown.medical_medicine_analgesic2 + 
                        this.$store.state.breakdown.medical_medicine_external + 
                        this.$store.state.breakdown.medical_medicine_etc + 
                        this.$store.state.breakdown.medical_medicine_supplement + 
                        this.$store.state.breakdown.medical_medicine_children +
                        this.$store.state.breakdown.medical_physical.reduce((sum, i) => { return sum + i.order; }, 0)});
      this.array.push({'title': '貯金', 'value': this.$store.state.breakdown.saving});
      this.array.push({'title': '生命保険', 'value': this.$store.state.breakdown.life_insurance_you + 
                        this.$store.state.breakdown.life_insurance_spouse});
      this.array.push({'title': '外食', 'value': this.$store.state.breakdown.eat_out.reduce((sum, i) => { return sum + i.order; }, 0)});
      this.array.push({'title': '習い事', 'value': this.$store.state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0) +
                        this.$store.state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0)});
      this.array.push({'title': '防災用品', 'value': this.$store.state.breakdown.emergency_kit});
      this.array.push({'title': '旅行', 'value': this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult; }, 0) +
                        this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child; }, 0)});
      this.array = this.sort();
      this.array_title = this.array.map((item) => {return item.title;});
      this.array_value = this.array.map((item) => {return item.value;});
      this.addPlugin(chartjsPluginDatalabels);
      this.renderChart(this.chartData, this.options);
    },
    methods: {
      sort() {
        return this.array.sort((a, b) => {return b.value - a.value;});
      }
    }
}
</script>