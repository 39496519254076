<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="car">{{ icon }}</v-icon>{{ name }}
        <v-select class="d-inline-flex align-center mt-2 ml-4" :items="this.$store.state.login_response.car" item-text="model" return-object :label="this.$store.state.select.car" @change="value_car"></v-select>
      </v-col>
      <v-col class="my-n6" cols="12">
        <v-img max-height="60" max-width="60" contain v-bind:src="require('@/assets/volkswagen.jpg')" v-if="this.$store.state.login_response.queryStringParameters.target == 'shinagawa'"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="4" lg="4" v-for="car in this.$store.state.login_response.car" :key="car.key">
            <v-card>
              <v-card-title>{{ car.model }}</v-card-title>
              <v-col align="center">
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/car/'+car.img+'.jpg')"></v-img>
              </v-col>
              <v-card-text>
                <v-simple-table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>モデル</td>
                      <td>{{ car.model }}</td>
                    </tr>
                    <tr>
                      <td>摘要</td>
                      <td>{{ car.outline }}</td>
                    </tr>
                    <tr>
                      <td>クラス</td>
                      <td>{{ car.class }}</td>
                    </tr>
                    <tr>
                      <td>販売価格</td>
                      <td>{{ car.price }}</td>
                    </tr>
                    <tr>
                      <td>月々支払い</td>
                      <td>{{ car.cost.toLocaleString() }}円</td>
                    </tr>
                    <tr>
                      <td>備考</td>
                      <td>{{ car.note }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-car',
    name: '自動車'
  }),
  methods: {
    value_car: function(value) {
      this.$store.commit('value_car', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  }
})
</script>
