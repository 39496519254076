<template>
  <v-row>
    <v-col class="d-inline-flex align-center" cols="12">
      <v-icon>{{ icon }}</v-icon>{{ name }}
      <v-select class="d-inline-flex align-center ml-4" :items=sortedWater item-text="select" item-value="cost" :label="this.$store.state.select.water" return-object @change="value_water"></v-select>
    </v-col>
    <v-col>
      <v-card min-height="100%" min-width="80%">
        <v-card-title>{{ title }}</v-card-title>
        <v-layout justify-center>
          <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/water/water.jpg')"></v-img>
        </v-layout>
        <v-card-title>{{ this.$store.state.breakdown.water.toLocaleString() }}円</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default({
  data: () => ({
    icon: 'mdi-water',
    name: '水道',
    title: '水道代'
  }),
  methods: {
    sortWater() {
      return this.$store.state.login_response.water.sort((a, b) => {return a.people - b.people;});
    },
    value_water: function(select) {
      console.log('value', select);
      this.$store.commit('value_water', select);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    }
  },
  computed: {
    sortedWater() {
      return this.sortWater();
    }
  }
})
</script>
