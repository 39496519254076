import Vue from 'vue';
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo,{
    duration: 100,//早すぎないように遅くする
    offset: -100,//オフセットで遷移先の要素がヘッダーに隠れるのを対策
    easing: "ease-out"//ease-outが直感的かと思い採用
})

export default new Vue({
});
