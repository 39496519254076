<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-icon id="smartphone">{{ icon }}</v-icon>{{ name }}
      </v-col>
      <v-col>
        <v-card class="mt-2" cols="6" sm="6" md="4" lg="4" max-width="520">
          <v-card-title>契約内容</v-card-title>
          <v-layout justify-center>
            <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/smartphone/smartphone.jpg')"></v-img>
          </v-layout>
          <v-card-text>
            <v-simple-table>
              <thead></thead>
              <tbody>
                <tr>
                  <td>契約人数(大人)</td>
                  <td>{{ num }}名</td>
                </tr>
                <tr>
                  <td>通信事業者/プラン</td>
                  <td>
                    <v-select :items=sortedSmartphone item-text="plan" item-value="cost" return-object :label="this.$store.state.select.smartphone" @change="value_smartphone"></v-select>
                  </td>
                </tr>
                <tr>
                  <td>支払い金額</td>
                  <td>{{ this.$store.state.breakdown.smartphone.toLocaleString() }}円</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
export default({
  data: () => ({
    icon: 'mdi-cellphone',
    name: 'スマートフォン',
    num: 1
  }),
  methods: {
    value_smartphone: function(value) {
      this.$store.commit('value_smartphone', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    sortSmartphone() {
      return this.$store.state.login_response.smartphone.sort((a, b) => {return a.id - b.id;});
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1) {
      this.num++;
    }
  },
  computed: {
    sortedSmartphone() {
      this.sortSmartphone();
      return this.$store.state.login_response.smartphone;
    }
  }
})
</script>
